'use strict';

angular.module('enervexSalesappApp').controller('FittingsCtrl', function($scope, Fitting, _) {
	$scope.all = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		Fitting.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(fittings){
			if (fittings.length > 0) {
				$scope.all = $scope.all.concat(fittings);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.fittings = $scope.all;
				// $scope.fittings = _.sortBy($scope.all, function(bomRule){
				// 	return bomRule.category
				// })
			}
		});
	}
	fetch();
	$scope.delete = function(bomRule) {
		Fitting.delete({
			id: bomRule._id
		});
		$scope.all = _.filter($scope.all, function(r){
			return r._id != bomRule._id
		})
		$scope.fittings = $scope.all;
		// $scope.applyFilter()
	}
	$scope.applyFilter = function() {
		var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

		$scope.fittings = _.filter($scope.all, function(fitting){
			if (nameReg && !(nameReg.test(fitting.name) || nameReg.test(fitting.code))) {
				return false;
			} else if (($scope.active && $scope.active != "" && ""+fitting.active != $scope.active)) {
				return false
			} else if ($scope.secno && $scope.secno != "" && ""+fitting.secno != $scope.secno) {
				return false
			} else {
				return true
			}
		})
	}
});
